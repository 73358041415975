<template>
    <SwContainer
        v-bind="{
            stretched,
            paddingY,
            bgColor,
            bgImage,
            bgOpacity,
        }"
    >
        <div
            :class="{
                [$style['block']]: true,
                [$style['block_align_center']]: align === 'center',
                [$style['block_align_right']]: align === 'right',
                [$style['block_inverted']]: inverted,
            }"
        >
            <h1
                :class="[$style['block__title'], 'sw-title']"
                v-html="title"
            />

            <div
                :class="[$style['block__text'], 'sw-text']"
                v-html="text"
            />

            <template v-if="noIndex">
                <client-only>
                    <nofollow>
                        <noindex>
                            <span :class="$style['block__link']">
                                <SwButton
                                    v-if="buttonUrl.includes('//')"
                                    :href="buttonUrl"
                                    target="_blank"
                                >
                                    {{ buttonLabel }}
                                </SwButton>

                                <SwButton v-else :to="buttonUrl">
                                    {{ buttonLabel }}
                                </SwButton>
                            </span>
                        </noindex>
                    </nofollow>
                </client-only>
            </template>

            <span v-else :class="$style['block__link']">
                <SwButton
                    v-if="buttonUrl.includes('//')"
                    :href="buttonUrl"
                    target="_blank"
                >
                    {{ buttonLabel }}
                </SwButton>

                <SwButton v-else :to="buttonUrl">
                    {{ buttonLabel }}
                </SwButton>
            </span>
        </div>
    </SwContainer>
</template>

<script>
export default {
  name: 'TextOnImage',

  props: {
    title: {
      type: String,
      default: 'Заголовок'
    },

    text: {
      type: String,
      default: 'Используйте текст над изображением, чтобы дать вашим клиентам представление о вашем бренде. Выберите изображение и текст, который относится к вашему стилю и истории.'
    },

    buttonLabel: {
      type: String,
      default: 'Кнопка CTA'
    },

    buttonUrl: {
      type: String,
      default: '/'
    },

    noIndex: {
      type: Boolean,
      default: false
    },

    stretched: {
      type: Boolean,
      default: false
    },

    paddingY: {
      type: String,
      default: 's'
    },

    bgColor: {
      type: String,
      default: '#FFFFFF'
    },

    bgImage: {
      type: String,
      default: ''
    },

    bgOpacity: {
      type: String,
      default: '0%'
    },

    align: {
      type: String,
      default: 'left'
    },

    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "../../../../assets/Web.less";

.block {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 606px;
  margin: auto 0;
  @media screen and (max-width: @bp-mobile-small) {
    max-width: 100%;
  }

  &&_align_center {
    align-items: center;
    text-align: center;
    margin: auto;
  }

  &_align_right {
    margin-left: auto;
  }

  &__title {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 32px;
  }

  &__text {
    display: block;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &_inverted &__title,
  &_inverted &__text {
    color: #FFFFFF;
  }

  &__link {
    display: block;
    min-width: 150px;
    margin-right: auto;
  }

  &_align_center &__title {
    text-align: center;
  }

  &_align_center &__link {
    margin: 0 auto;
  }
}
</style>
